import Service, { APIVersion as libApiVersion } from 'additiv-services';
import {
    getServicesUrl, getDmsUrl, getClientServiceHeader, getBearerAccessTokenByType,
    getContactGroupServiceUrl, getClientServiceUrl,
} from 'additiv-services/dist/setup';
import { ServiceTypes } from 'additiv-services/dist/types/main';
import Security from 'additiv-services/dist/services/core/security';

const APIVersion = {
    ...libApiVersion,
    V23: 'v2.3',
};

export default class OOTBServices extends Service {
    static async getCurrencies() {
        const data = {
            Page: 1,
            PageSize: 100,
        };

        return Service.postRequest(`${getServicesUrl(APIVersion.V10)}/currencies/search`, data, {
            isSecure: true,
        });
    }

    static async postMembersMessages(data, memberId) {
        return Service.postRequest(
            `${getServicesUrl(APIVersion.V21)}/members/${memberId}/messages`,
            data,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    static async postContactMessages(data, contactId) {
        return Service.postRequest(
            `${getServicesUrl(APIVersion.V21)}/contacts/${contactId}/messages`,
            data,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    static async getContactGroup(contactGroupId) {
        return Service.getRequest(
            `${getServicesUrl(APIVersion.V10)}/contactgroups/${contactGroupId}`,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    static async getContactGroups(contactId) {
        return Service.getRequest(
            `${getServicesUrl(APIVersion.V20)}/contacts/${contactId}/contactgroups`,
            { isSecure: true, axiosSetup: { headers: getContactGroupServiceUrl() } },
        );
    }

    static postDocument(data) {
        return Service.postRequest(`${getDmsUrl(APIVersion.V23)}/documents`, data, {
            isSecure: true,
            type: ServiceTypes.DMS,
        });
    }

    static async getResourceSets(resourceSetName, language) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.getRequest(
            `${getServicesUrl(APIVersion.V11)}/resourcesets/${resourceSetName}`,
            {
                isSecure: true,
                axiosSetup: {
                    headers: {
                        ...getClientServiceHeader(),
                        'Accept-Language': language,
                        Authorization: getBearerAccessTokenByType('APP'),
                    },
                },
            },
        );
    }

    static async changePasswordByContactIdWithPolicies({ contactId, payload }) {
        return Service.patchRequest(
            `${getServicesUrl(APIVersion.V10)}/contacts/${contactId}/password`,
            payload,
            { isSecure: true },
        );
    }

    static async changePasswordByActivationCodeWithPolicies(key, payload) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.postRequest(
            `${getServicesUrl(APIVersion.V10)}/contacts/reset-password/${key}`,
            payload,
            {
                isSecure: true,
                axiosSetup: {
                    headers: {
                        Authorization: getBearerAccessTokenByType('APP'),
                    },
                },
            },
        );
    }

    // CLIENTSERVICES
    static async clientServicesGetSchemasListsItems() {
        return Service.getRequest(
            `${getClientServiceUrl(APIVersion.V11)}/schemas/lists/items`,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    static async clientServicesGetMemberProfilePicture(memberId) {
        return Service.getRequest(
            `${getServicesUrl(APIVersion.V10)}/members/${memberId}/profilepicture`,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    static async clientServicesGetContactProfilePicture(contactId) {
        return Service.getRequest(
            `${getServicesUrl(APIVersion.V10)}/contacts/${contactId}/profile-picture`,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    static async clientServicesGetContactsAdvisorProfilePicture(contactId, memberId) {
        return Service.getRequest(
            `${getServicesUrl(APIVersion.V10)}/contacts/${contactId}/member/${memberId}/profile-picture`,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    // contact identity services
    static async contactSignInWithKey(key) {
        await Security.generateBearerApplicationAccessTokenIfNeeded();

        return Service.postRequest(
            `${getServicesUrl(APIVersion.V10)}/contacts/sign-in/enable/${key}`, undefined,
            {
                isSecure: true,
                axiosSetup: {
                    headers: {
                        Authorization: getBearerAccessTokenByType('APP'),
                    },
                },
            },
        );
    }
}
